<template>
  <div>
    <b-row>
      <b-col class="mt-2" cols="4" v-for="(item, index) in icons" :key="index">
        <div
          :style="`border-radius: 10px; ${
            item.default ? 'border: 2px solid rgb(128 77 227)' : ''
          }`"
        >
          <b-card>
            <div class="text-center">
              <img :src="item.image" alt="icon-img" class="img-fluid" width="100px">
              <span
                :class="[
                  'text-truncate',
                  'd-block',
                  'w-100',
                  'mt-2',
                  item.default ? 'default-color' : 'custom-color',
                ]"
                style="font-size: 1.5rem; font-weight: bold"
              >{{ item.key }}</span>
            </div>
            <div style="position: absolute; right: -20px; top: -4px">
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="editItem(index)">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteItem(index)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-card>
        </div>
      </b-col>
    </b-row>
    <div class="text-center mt-2">
      <b-button @click="openModal" size="sm" pill variant="gradient-primary">
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span>Add New</span>
      </b-button>
    </div>

    <b-modal
      id="content"
      ok-only
      ok-title="Save"
      centered
      title="Content"
      v-model="isOpen"
      @ok="save"
      @close="closeModal"
      @hidden="closeModal"
    >
      <b-form-group label="Icon" label-for="key">
        <b-form-select id="key" v-model="content.key" :options="iconsConfigOptions" />
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
import configService from '../../../config/service'
import { REFERRAL_NETWORK, MODULE_CATEGORY } from './../../constants'
export default {
  name: "CardTextField",
  props: {
    icons: { type: Array, required: true },
  },
  data() {
    return {
      content: {},
      isOpen: false,
      indexUpdate: null,
      iconsConfig: null,
      iconsConfigOptions: null,
    };
  },
  watch: {
    'content.key': function (newValue) {
      if (this.iconsConfig && newValue) {
        const icon = this.iconsConfig.find(icon => icon.key === newValue)
        this.content.image = icon.image
        this.content.referral_id = icon.referral_id
      }
    }
  },
  async created() {
    await this.getIconsConfig()
  },
  methods: {
    async getIconsConfig() {
      const res = await configService.getByKey({ key: REFERRAL_NETWORK.key }, { default_data: REFERRAL_NETWORK.default_data, category: MODULE_CATEGORY })
      this.iconsConfig = res.value
      this.iconsConfigOptions = this.iconsConfig.map(icon => {
        return {
          text: icon.key,
          value: icon.key
        }
      })
    },
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.indexUpdate = null
      this.content = {}
    },
    deleteItem(index) {
      this.icons.splice(index, 1);
    },
    editItem(index) {
      this.content = this.icons[index];
      this.indexUpdate = index;
      this.openModal();
    },
    save() {
      if (this.content.default) {
        this.icons.forEach((item, index) => {
          if (this.indexUpdate !== index) {
            item.default = false;
          }
        });
      }
      if (this.indexUpdate || this.indexUpdate == 0) {
        this.icons[this.indexUpdate] = JSON.parse(JSON.stringify(this.content));
        this.indexUpdate = null;
      } else {
        this.icons.push(this.content);
      }

      this.content = {};
    },
  },
};
</script>
<style scoped>
.default-color {
  color: black;
}
.custom-color {
  color: black;
}
.footer-text-color {
  color: black;
}
.text-body {
  color: black !important;
}
.card {
  margin-bottom: 0;
}
.custom-hr {
  border-top: 2px solid rgb(128 77 227);
  margin: 0;
}
.default-hr {
  border-top: 2px solid white;
  margin: 0;
}
.row {
  margin: 0;
  border-radius: 5px;
}
</style>
