import api from '@/libs/axios'
import { DISCOUNT_OPTION_USED_CONFIG } from './constants'
import configService from './../config/service'

export default {
  async getList(params) {
    let response = await api.get('/admin/iap-popups', {
      params,
    })
    return response
  },
  async update(payload) {
    let response = await api.put('/admin/iap-popups', payload)
    return response
  },
  async create(payload) {
    const response = await api.post('/admin/iap-popups', payload)
    return response
  },
  async delete(payload) {
    const response = await api.delete('/admin/iap-popups', {
      data: payload,
    })
    return response
  },
  async getDiscountOptionUsedConfig() {
    return await configService.getByKey({ key: DISCOUNT_OPTION_USED_CONFIG.key }, { default_data: DISCOUNT_OPTION_USED_CONFIG.default_data, category: DISCOUNT_OPTION_USED_CONFIG.category })
  },
  async updateDiscountOptionUsedConfig(payload) {
    let response = await api.put('/admin/configs', payload)
    return response
  },
}
