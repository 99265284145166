<template>
  <b-modal
    id="modal-scrollable"
    scrollable
    title="Iap Popup Content"
    cancel-title="Close"
    ok-title="Save"
    cancel-variant="outline-secondary"
    v-model="isOpen"
    @ok="save"
    size="xl"
  >
    <b-form-group label="Option Type" label-for="option_type">
      <b-form-select
        v-model="iap_popup.option_type"
        class="custom-control-primary"
        :options="OPTION_TYPES_OPTIONS"
      />
    </b-form-group>
    <discount-form v-if="isDiscountOption" :discount-info="iap_popup.discount_info" :option-type="iap_popup.option_type" />
    <b-form-group label="Is Multiple Title" label-for="is_multiple_title">
      <b-form-checkbox
        v-model="iap_popup.is_multiple_title"
        class="custom-control-primary"
        name="check-button"
        switch
      >
        <span class="switch-icon-left">
          <feather-icon icon="CheckIcon" />
        </span>
        <span class="switch-icon-right">
          <feather-icon icon="CheckIcon" />
        </span>
      </b-form-checkbox>
    </b-form-group>
    <b-form-group v-if="!iap_popup.is_multiple_title" label="Title" label-for="title">
      <b-form-input v-model="iap_popup.title" id="title" placeholder="Title" />
    </b-form-group>
    <b-form-group v-else label="Multiple Title" label-for="multiple_title">
      <multiple-title :component-data="iap_popup.multiple_title" id="multiple_title" />
    </b-form-group>
    <b-form-group label="Subtitle" label-for="subtitle">
      <b-form-input v-model="iap_popup.subtitle" id="subtitle" placeholder="Subtitle" />
    </b-form-group>
    <b-form-group label="Type" label-for="type">
      <b-form-input v-model="iap_popup.type" id="type" placeholder="Type" />
    </b-form-group>
    <b-form-group label="Language" label-for="language">
      <!-- <b-form-select v-model="iap_popup.language" :options="languages" /> -->
      <language-select :language="iap_popup.language" @selectedLanguage="selectedLanguage" />
    </b-form-group>
    <b-form-group label="Content" label-for="content">
      <ui-component-list :component-list="iap_popup.content" />
    </b-form-group>
    <b-form-group label="Product" label-for="product">
      <b-card>
        <product-form-modal :products="iap_popup.products" />
      </b-card>
    </b-form-group>
    <b-form-group label="Icon" label-for="icons">
      <b-card>
        <icon-form-modal :icons="iap_popup.icons" />
      </b-card>
    </b-form-group>

    <b-form-group label="Button Text" label-for="button_text">
      <b-form-input v-model="iap_popup.button_text" />
    </b-form-group>

    <b-form-group label="Button Sub Text" label-for="button_sub_text">
      <b-form-input v-model="iap_popup.button_sub_text" />
    </b-form-group>

    <b-form-group label="Timeup Text" label-for="timeup-text">
      <b-form-input v-model="iap_popup.timeup.text" />
    </b-form-group>

    <b-form-group label="Timeup Endtime" label-for="timeup-endtime">
      <datetime-picker
        :config="{ enableTime: true, altFormat: 'd/m/Y, H:i', dateFormat: 'Z', }"
        v-model="iap_popup.timeup.endtime"
      />
    </b-form-group>

    <template #modal-footer="{ ok, cancel }">
      <div class="w-100">
        <b-button class="float-left mr-1" variant="dark" @click="copyToClipboard">
          <b-spinner v-if="isLoading" small class="mr-50" />Copy
        </b-button>
        <b-button
          class="float-left"
          variant="dark"
          :disabled="isLoading"
          @click="pasteFromClipboard"
        >
          <b-spinner v-if="isLoading" small class="mr-50" />Paste
        </b-button>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button class="float-right" variant="primary" @click="ok()">Save</b-button>
        <b-button class="float-right mr-1" variant="outline-secondary" @click="cancel()">Close</b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import MultipleTitle from '@core/layouts/components/input-fields/multiple-title-field/Field.vue'
import { OPTION_TYPES, OPTION_TYPES_OPTIONS } from '../../constants';
import DiscountForm from './DiscountForm.vue'
import ProductFormModal from './ProductFormModal.vue';
import IconFormModal from './IconFormModal.vue';
export default {
  components: {
    MultipleTitle,
    DiscountForm,
    ProductFormModal,
    IconFormModal,
  },
  props: {
    dataUpdate: { type: Object, default: null },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      iap_popup: {
        title: null,
        type: null,
        multiple_title: { type: 'multiple-title', titles: [''] },
        language: "vi",
        content: [],
        button_text: null,
        option_type: 0,
        discount_info: {
          title: null,
          is_multiple_title: false,
          multiple_title: { type: 'multiple-title', titles: [''] },
          content: [],
        },
        products: [],
        icons: [],
        timeup:{
          text: null,
          endtime: null
        }
      },
      OPTION_TYPES_OPTIONS,
    };
  },
  computed: {
    isDiscountOption() {
      return this.iap_popup.option_type && (this.iap_popup.option_type === OPTION_TYPES.DISCOUNT)
    },
  },
  watch: {
    dataUpdate: function (newValue) {
      if (newValue) {
        this.iap_popup = { ...this.iap_popup, ...newValue };
      } else {
        this.resetData();
      }
    },
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    save() {
      if (this.dataUpdate) {
        this.$emit("update", this.iap_popup);
      } else {
        this.$emit("create", this.iap_popup);
      }
      this.resetData();
    },
    resetData() {
      this.iap_popup = {
        title: null,
        multiple_title: { type: 'multiple-title', titles: [''] },
        type: null,
        language: "vi",
        content: [],
        button_text: null,
        option_type: 0,
        discount_info: {
          title: null,
          is_multiple_title: false,
          multiple_title: { type: 'multiple-title', titles: [''] },
          content: [],
        },
        products: [],
        icons: [],
        timeup:{
          text: null,
          endtime: null
        }
      };
    },
    selectedLanguage(language) {
      this.iap_popup.language = language;
    },
    async copyToClipboard() {
      this.isLoading = true
      let copy = JSON.parse(JSON.stringify(this.iap_popup))
      if (copy._id) { delete copy._id }

      await navigator.clipboard.writeText(JSON.stringify(copy))
      this.isLoading = false
    },
    async pasteFromClipboard() {
      this.isLoading = true
      const paste = await navigator.clipboard.readText()
      if (JSON.parse(paste)) {
        this.iap_popup = JSON.parse(paste)
      }

      this.isLoading = false
    },
  },
};
</script>
<style scoped>
.d-block {
  font-size: 1.1rem !important;
}
</style>
