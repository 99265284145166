<template>
  <b-card>
    <strong class="h4"><center>Discount Information</center></strong>
    <b-form-group label="Is Multiple Title" label-for="is_multiple_title">
      <b-form-checkbox
        v-model="discountInfo.is_multiple_title"
        class="custom-control-primary"
        name="check-button"
        switch
      >
        <span class="switch-icon-left">
          <feather-icon icon="CheckIcon" />
        </span>
        <span class="switch-icon-right">
          <feather-icon icon="CheckIcon" />
        </span>
      </b-form-checkbox>
    </b-form-group>
    <b-form-group v-if="!discountInfo.is_multiple_title" label="Title" label-for="title">
      <b-form-input v-model="discountInfo.title" id="title" placeholder="Title" />
    </b-form-group>
    <b-form-group v-else label="Multiple Title" label-for="multiple_title">
      <multiple-title :component-data="discountInfo.multiple_title" id="multiple_title" />
    </b-form-group>
    <b-form-group label="Subtitle" label-for="subtitle">
      <b-form-input v-model="discountInfo.subtitle" id="subtitle" placeholder="Subtitle" />
    </b-form-group>
    <b-form-group label="Countdown Text" label-for="countdown_text">
      <b-form-input v-model="discountInfo.countdown_text" id="countdown_text" placeholder="Thời gian còn lại ưu đãi:" />
    </b-form-group>
    <b-form-group label="Countdown Time" label-for="countdown_time">
      <b-form-input v-model="discountInfo.countdown_time" type="number" id="countdown_time" placeholder="5" />
    </b-form-group>
    <b-form-group label="Content" label-for="content">
      <ui-component-list :component-list="discountInfo.content" />
    </b-form-group>
  </b-card>
</template>
<script>
import MultipleTitle from '@core/layouts/components/input-fields/multiple-title-field/Field.vue'
import { OPTION_TYPES } from '../../constants';
export default {
  components: {
    MultipleTitle,
  },
  props: {
    discountInfo: { type: Object, default: null },
    optionType: { type: Number, default: 0 },
  },
  data() {
    return {
      isOpen: false,
      OPTION_TYPES,
    };
  },
  watch: {
    'discountInfo.countdown_time'() {
      this.discountInfo.countdown_time = parseInt(this.discountInfo.countdown_time)
    }
  },
  methods: {
  },
};
</script>
<style scoped>
.d-block {
  font-size: 1.1rem !important;
}
</style>
